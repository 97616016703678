import { compareVersion } from '@airpaz/apz-js';

import {
  useConfigStore,
  useDevice,
  usePromoService,
  useI18n,
  useAsyncData,
  useNuxtApp,
  useNavigation,
  useTracker,
  useAppConfig,
  useRoute,
  useDateFormatter,
  useLocalePath,
  useRuntimeConfig,
  useCurrencyFormatter,
  useClipboard,
  useToast,
  computed,
  ref,
} from '#imports';

import { Currency } from '@/types/master';
import { PromoCarouselBanner, PromoCard, PromoDetail, PromoCode } from '@/types/promo';

export const usePromoCarousel = () => {
  const { locale } = useI18n();
  const { country } = useConfigStore();
  const { defaults } = useAppConfig();
  const { $device } = useNuxtApp();
  const { navigationPath } = useNavigation();
  const { ga, jitsuEvent } = useTracker();
  const route = useRoute();

  const swiperReady = ref<boolean>(false);

  const { data: promoCarouselData } = useAsyncData(
    () =>
      usePromoService().getCarousel({
        lang: locale.value,
        country: country!,
        platform: 'desktop',
        type: 'general',
      }),
    {
      transform: (data) =>
        data.result.map((item, index) => {
          if (index === 0 && item.img) {
            item.img = item.img.replace('cdn.airpaz.com', 'www.airpaz.com');
          }

          return item;
        }),
    },
  );

  function getPromoPath(promo: PromoCarouselBanner) {
    const storeLink = $device.isAndroid ? defaults.appstoreUrl : defaults.playstoreUrl;

    return promo.slug
      ? promo.slug === 'app-store'
        ? storeLink
        : navigationPath(`/promo/view/${promo.slug}`)
      : promo.url
      ? promo.url
      : navigationPath('/');
  }

  function onClickCarouselItem(item: PromoCarouselBanner, index: number) {
    ga('general_choose_carousel', 'general_choose_carousel', item.slug || '');
    jitsuEvent('user-click-placeholder', {
      object_name: 'promo-banner',
      object_parameter: item.slug || '',
      pos: index,
    });
  }

  function onClickSwiperNav(type: string) {
    ga('general_toggle_carousel', 'general_toggle_carousel', type);
    jitsuEvent('user-click-button', {
      object_name: 'promo-banner',
      object_parameter: type === 'prev' ? 'left' : 'right',
      screen_name: route.name!.toString(),
    });
  }

  function onSlideCarousel() {
    jitsuEvent('user-slide-navigation', {
      object_name: 'promo-banner',
      object_parameter: 'carousel',
      screen_name: route.name!.toString(),
    });
  }

  return {
    promoCarouselData,
    swiperReady,
    getPromoPath,
    onClickCarouselItem,
    onClickSwiperNav,
    onSlideCarousel,
  };
};

export const usePromoList = (promos: PromoCard[]) => {
  const { t } = useI18n();
  const { navigationPath } = useNavigation();
  const { ga } = useTracker();

  const device = useDevice();
  const config = useConfigStore();
  const storeLink = device.isIos ? config.playstoreUrl! : config.appstoreUrl!;

  const tabItems = [
    { id: 'all', text: t('promo.all') },
    { id: 'flight', text: t('global.flight') },
    { id: 'hotel', text: t('global.hotel') },
  ];

  const selectedTab = ref<string>(tabItems[0]!.id);

  const bannerPromos = computed(
    () =>
      promos.map((p) => ({
        img: p.banner,
        slug: p.slug,
        description: p.description,
        title: p.title,
      })) ?? [],
  );

  const filteredPromos = computed(() => {
    if (selectedTab.value !== 'all') {
      return promos.filter((promo) => promo.type === selectedTab.value);
    } else {
      return promos;
    }
  });

  function getPromoPath(promo: PromoCard) {
    return promo.slug
      ? promo.slug === 'app-store'
        ? storeLink
        : navigationPath(`/promo/view/${promo.slug}`)
      : promo.url
      ? promo.url
      : navigationPath('/');
  }

  function selectTab(tab: (typeof tabItems)[0]) {
    ga('promo_list_tab', 'promo_list_tab', tab.id);
    selectedTab.value = tab.id;
    window?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return {
    tabItems,
    selectedTab,
    bannerPromos,
    filteredPromos,
    getPromoPath,
    selectTab,
  };
};

export const usePromoDetail = (promo: PromoDetail, currencies: Currency[]) => {
  const route = useRoute();
  const runtimeConfig = useRuntimeConfig();
  const toast = useToast();
  const formatDate = useDateFormatter();
  const localePath = useLocalePath();
  const currencyFormatter = useCurrencyFormatter();
  const device = useDevice();
  const { isSupported: isClipboardSupported, copy } = useClipboard();

  const { ssrContext } = useNuxtApp();
  const { t } = useI18n();

  const isDicountUpTo = computed(() => /^birthday/gi.test(route.params.slug as string));

  const isExpired = computed(() => promo.status === 'expired');

  const isNativeShareSupported = computed(() => typeof navigator !== 'undefined' && Boolean(navigator.share));

  const shareUrl = computed(() => {
    const host = ssrContext?.event.node.req.headers.host || window.location.host;
    return `${host}${localePath(route.fullPath)}`;
  });

  const promoShareUrl = computed(() => {
    return !shareUrl.value.startsWith('https://') ? 'https://' + shareUrl.value : shareUrl.value;
  });

  const promoInfoList = computed(() => {
    const list = [];

    if (promo.platform) {
      list.push({
        icon: 'mobile-alt',
        content: t(`promo.platform_${promo.platform}`),
      });
    }

    if (!!promo.startPeriod && !!promo.endPeriod) {
      list.push({
        icon: 'calendar-check',
        content: `${t('promo.period')} ${formatDate(promo.startPeriod, 'LL')} - ${formatDate(promo.endPeriod, 'LL')}`,
      });
    }

    return list;
  });

  const caption = promo.type === 'hotel' ? t('promo.checkpromohotel') : t('promo.checkpromo');

  const socialMediaLinks = {
    facebook: {
      id: 'facebook',
      link: `https://www.facebook.com/dialog/share?app_id=${
        runtimeConfig.public.facebookClientId
      }&display=touch&href=${encodeURI(promoShareUrl.value)}&caption=${caption}&description=${promo.description}`,
      icon: ['fab', 'facebook-f'],
    },
    twitter: {
      id: 'twitter',
      link: `http://twitter.com/share?text=${caption}&url=${promoShareUrl.value}&hashtags=airpaz,promo,${promo.type}`,
      icon: ['fab', 'twitter'],
    },
    whatsapp: {
      id: 'whatsapp',
      link: `https://api.whatsapp.com/send?text=${caption} ${encodeURI(promoShareUrl.value)}`,
      icon: ['fab', 'whatsapp'],
    },
    email: {
      id: 'email',
      link: `mailto:?subject=${promo.description}&body=${caption} : ${promoShareUrl.value}`,
      icon: ['fas', 'envelope'],
    },
  };

  function share(socmedId: keyof typeof socialMediaLinks) {
    const { link } = socialMediaLinks[socmedId];

    if (
      typeof window.ReactNativeWebView !== 'undefined' &&
      compareVersion(device.mobileAppVersion ?? '', '3.57.0') > 0
    ) {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          key: 'share',
          payload: {
            title: caption,
            message: promo.description,
            url: promoShareUrl.value,
            social: socmedId,
          },
        }),
      );

      return;
    }

    window.location.href = link;
  }

  async function copyToClipboard(code: PromoCode['code']) {
    if (device.isAirpazWebView && typeof window.ReactNativeWebView !== 'undefined') {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ key: 'copyToClipboard', payload: code }));
      return;
    }

    if (!isClipboardSupported) return;

    await copy(code).then(() => {
      toast.show({
        message: t('global.copied'),
        type: 'success',
      });
    });
  }

  async function openShareNavigator() {
    try {
      if (!isNativeShareSupported.value) {
        return;
      }

      const dataUrl = {
        title: promo.title,
        url: promoShareUrl.value,
      };

      if (navigator.share && navigator.canShare) {
        if (navigator.canShare(dataUrl)) {
          await navigator.share(dataUrl);
        } else {
          throw new Error('Sharing data are not supported.');
        }
      } else {
        throw new Error('Web share API is not supported in this browser.');
      }
    } catch (e: any) {}

    return true;
  }

  function quotaText(voucher: PromoCode) {
    if (!(voucher.quota && +voucher.quota > 0)) {
      return null;
    }

    return t('promo.descquota1', [
      voucher.fixValue ? currencyFormatter(voucher.fixValue, getCurrency(voucher.currency)) : `${voucher.percentage}%`,
      currencyFormatter(voucher.minPurchase, getCurrency(voucher.currency)),
      voucher.quota,
    ]);
  }

  function hasMinAmount(voucher: PromoCode) {
    return voucher.minPurchase && +voucher.minPurchase > 0;
  }

  function getCurrency(currency: string) {
    return currencies.find((curr) => curr.currency === currency);
  }

  return {
    caption,
    isDicountUpTo,
    isExpired,
    promoShareUrl,
    promoInfoList,
    shareUrl,
    socialMediaLinks,
    isNativeShareSupported,
    openShareNavigator,
    copyToClipboard,
    getCurrency,
    hasMinAmount,
    quotaText,
    share,
  };
};
